// in src/comments.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import { translate, TextField } from 'react-admin';

// const cardStyle = {
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     margin: '0.5rem 0',
// };

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: theme.typography.body1,
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
});

const MobileGrid = withStyles(listStyles,{ name: 'mobile-grid-files' })(
    translate(({ classes, ids, data, basePath, translate }) => (
        <div style={{ margin: '1em' }}>
            {ids.map(id => (
                <Card key={id} >
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <span>
                                    <TextField record={data[id]} source="name.de"/>
                                </span>
                            </div>
                        }
                    />
                    <CardContent className={classes.cardContent}>
                        <span className={classes.cardContentRow}>
                            <TextField record={data[id]} source="name.de" />&nbsp;
                            <TextField record={data[id]} source="name.en" />
                        </span>

                        <span className={classes.cardContentRow}>
                            Article ID:&nbsp;
                            <TextField record={data[id]} source="year" />
                        </span>

                    </CardContent>
                </Card>
            ))}
        </div>
    ))
);

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;

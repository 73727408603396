import React from 'react';
import { List, Datagrid, TextField, ReferenceField, DateField, Responsive } from 'react-admin';
// import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Comment';

import MobileGrid from './MobileGrid';

export const FilesIcon = Icon;


export const FilesList = (props) => (
    <List {...props} title="All Files" >
        <Responsive
            xsmall={<MobileGrid />}
            medium={
                <Datagrid>
                    <TextField label="ID" source="_id" />
                    <TextField source="name" />
                    <DateField source="uploadDate" />
                    <ReferenceField label="File" source="taskId" reference="task">
                        <TextField source="name" />
                    </ReferenceField>

                </Datagrid>
            }
        />
    </List>
);
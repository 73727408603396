import React from 'react';
// import Link from 'react-router-dom';

class Download extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount(){
    const id = this.props.match.params.id;
    window.location.href = `${process.env.REACT_APP_API}/task/download/${id}`;
    // window.close()
  }

  render() {
    const id = this.props.match.params.id;
    return id;
  }
}

export default Download;

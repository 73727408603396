import { stringify } from 'query-string';
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE_MANY,
  DELETE_MANY,
  fetchUtils,
} from 'react-admin';

const apiUrl = `${process.env.REACT_APP_API}`;

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */

 const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
   const { headers, json } = response;

    // console.log(`${type} ${resource}`)
    // console.log('json',json)
    // headers.set('x-access-token', localStorage.getItem('token'));
    switch (type) {
      case GET_LIST:
      if(resource === 'user' || resource === 'season' || resource === 'category'){
        return {
          data: json.map(resource => ({ ...resource, id: resource._id }) ),
          total: parseInt(headers.get('X-Total-Count').split('/').pop(), 10)
        }
      }
      if(resource === 'nav'){
        return {
          data: {...json, id: 123},
          total: 2
        }
      }
      if(resource === 'nav2')
        return json
      else {
        return {
          data: json,
          total: parseInt(headers.get('X-Total-Count').split('/').pop(), 10)
        };
      }
      case GET_MANY_REFERENCE:
      return { data: json }
      case CREATE:
      return { data: { ...params.data, id: json.id } };
      case GET_ONE:
      if(resource === 'season' || resource === 'category')
        return { data: { ...json, id: json._id } }
      else
        return { data: json }

      case DELETE:
      return { data: json }
      case GET_MANY:
        // console.log(resource, { data: json.map(item => ({ ...item, id: item._id }) ) })
        if(resource === 'season' || resource === 'category')
          return { data: json.map(item => ({ ...item, id: item._id }) ) }
        else
          return { data: json }
        case UPDATE_MANY:
        return { data: json };
        default:
        return { data: json };
      }
    };





    export default (type, resource, params) => {
      let url = '';
      let query = {};
      const { fetchJson } = fetchUtils;
      const options = { 
        headers : new Headers({
          Accept: 'application/json',
          "x-access-token": localStorage.getItem('token'),
        }),
      };
      switch (type) {
        case GET_LIST: {
          if(resource === 'user' || resource === 'season' || resource === 'category'){
            url = `${apiUrl}/${resource}`;
          }
          else if(resource === 'nav' || resource === 'nav2'){
            url = `${apiUrl}/user/${resource}`;
          }
          else {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            query = {
              start: (page - 1) * perPage,
              end: page*perPage,
              order: order,
              sort: field,
              filter: JSON.stringify(params.filter)
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
          }
          options.method = 'GET';
          break;
        }
        case "GET_STATS":
          options.method = 'POST';
          url = `${apiUrl}/season/stats/`;
        break;
        case GET_ONE:
          // if(resource === 'stats')
          // url = `${apiUrl}/season/${resource}`;
          if(resource === 'user')
            url = `${apiUrl}/${resource}`;
          else if(resource === 'check_user')
            url = `${apiUrl}/user/${params.id}`;
          else if(resource === 'checkPasswordLostHash')
            url = `${apiUrl}/user/${resource}/${params.passwordLostHash}`;
          else
            url = `${apiUrl}/${resource}/${params.id}`;
        break;
                case CREATE:
                url = `${apiUrl}/${resource}`;
                options.method = 'PUT';
                options.body = JSON.stringify(params.data);
                break;
                case UPDATE:
                  if(resource === 'user'){
                    url = `${apiUrl}/${resource}`;
                    options.method = 'PATCH';
                  }
                  else if(resource === 'verify'){
                    url = `${apiUrl}/user/${resource}`;
                    options.method = 'PATCH';
                  }
                  else if(resource === 'taskChecked'){
                    url = `${apiUrl}/task/check/${params.id}`;
                    options.method = 'PUT';
                  }
                  else if(resource === 'passwordLost'){
                    url = `${apiUrl}/user/${resource}`;
                    options.method = 'POST';
                  }
                  else if(resource === 'setPassword'){
                    url = `${apiUrl}/user/${resource}`;
                    options.method = 'PATCH';
                  }
                  else if(resource === 'attachments' || resource === 'finalFiles' || resource === 'files'){
                    url = `${apiUrl}/task/${resource}/${params.id}`;
                    options.method = 'PUT';
                  }
                  else if(resource === 'fileattr'){
                    url = `${apiUrl}/task/${resource}/${params.id}`;
                    options.method = 'POST';
                  }

                  else {
                    url = `${apiUrl}/${resource}/${params.id}`;
                    options.method = 'PUT';
                  }
                  options.body = JSON.stringify(params.data);
                break;
                case UPDATE_MANY:
                query = {
                  filter: JSON.stringify({ id: params.ids }),
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                options.method = 'PATCH';
                options.body = JSON.stringify(params.data);
                break;
                case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.body = JSON.stringify(params.data);
                options.method = 'DELETE';
                break;
                case DELETE_MANY:
                query = {
                  filter: JSON.stringify({ id: params.ids }),
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                options.method = 'DELETE';
                break;
                case GET_MANY:
                if(resource === 'export'){
                  query = {
                    filter: JSON.stringify({ id: params.ids }),
                  };
                  url = `${apiUrl}/task/${resource}?${stringify(query)}`;
                  break;
                }
                else {
                  query = {
                    filter: JSON.stringify({ id: params.ids }),
                  };
                  url = `${apiUrl}/${resource}?${stringify(query)}`;
                  break;
                }
                case GET_MANY_REFERENCE: {
                  const { page, perPage } = params.pagination;
                  const { field, order } = params.sort;
                  query = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([
                      (page - 1) * perPage,
                      page * perPage - 1,
                      ]),
                    filter: JSON.stringify({
                      ...params.filter,
                      [params.target]: params.id,
                    }),
                  };
                  url = `${apiUrl}/${resource}?${stringify(query)}`;
                  break;
                }
                default:
                throw new Error(`Unsupported Data Provider request type ${type}`);
              }

              return fetchJson(url, options)
              .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));

    // return fetch(url, options)
    //     .then(res => res.json())
    //     .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
  };
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import MetisMenu from 'react-metismenu';
import { GET_LIST } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

/* LOCAL */
import dataProvider from './dataProvider';

/* CSS */
import './css/react-metismenu-standart.min.css'
import './css/font-awesome.min.css'

const menuStyle = {
  root: {
    display: 'block',
  },
  menu: {
    height: '100%',
  }
};


class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = { menu: [] }
  }

  componentDidMount() {
    dataProvider(GET_LIST,'nav2')
    .then(menu => {
      this.setState(prevState => ({
        menu: menu
      }));
    }).then(() => {
      
    })
  }

  changeActive(item) {
    this.refs.menu.changeActiveLinkTo(item);
  }

  render(){
    const { classes } = this.props;
    const { menu } = this.state;
    return (
      <Paper className={classes.root}>
        <MetisMenu className={classes.menu} content={menu} activeLinkFromLocation ref="menu" />
      </Paper>
    )
  }
}

export default withStyles(menuStyle, { name: 'metis-menu' })(Menu);
import React from 'react';
import Card from '@material-ui/core/Card';
import {Doughnut} from 'react-chartjs-2';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { GET_ONE } from 'react-admin';

import dataProvider from '../dataProvider';

const styles = {
  card: {
    maxWidth: 345,
    minWidth: 250,
    display: 'inline-block',
    margin: '0px 15px 30px 15px',
    padding: 15,
  }
};

// function CountryName ({ countryId }) {

//   return null;
// }

class Chart extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      country: []
    }
  }

  componentDidMount(){
    const { item } = this.props;
    dataProvider(GET_ONE, 'country', { id: item.countryId})
    .then(res => {
      this.setState({ [item.countryId]: res.data.name })
    })
  }

  render(){
    const { item, classes } = this.props;
    return(
        <Card key={item.id} className={classes.card}>
          <Doughnut
            data={item}
            width={100}
            height={100}
            padding={10}
            // options={{
            //   maintainAspectRatio: false
            // }}
          />
          <CardContent>
            <Typography gutterBottom component="h3">
              {this.state[item.countryId]}
            </Typography>
            <Typography gutterBottom variant="headline" component="h2">
              {item.name}
            </Typography>
          </CardContent>
          <CardActions>
            <Button component={Link} to={`/task?filter={"seasonId":"${item.id}"}&order=DESC&page=1&perPage=10&sort=id`} size="small" color="primary"> 
              Ansehen
            </Button>
          </CardActions>
        </Card>
    )
  }
}

export default withStyles(styles, { name: 'dashboard-chart' })(Chart);
import React from 'react';
import { List, Create,  SelectInput, Edit, SimpleForm, DateField, TextInput, ReferenceInput, ReferenceField, Datagrid, TextField, Responsive } from 'react-admin';
// import withStyles from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Comment';
import ReviewEditActions from './ReviewEditActions';

import MobileGrid from './MobileGrid';

export const CategoryIcon = Icon;


export const CategoryList = (props) => (
    <List {...props} title="All Categories" >
        <Responsive
            xsmall={<MobileGrid />}
            medium={
                <Datagrid>
                    <TextField source="name.de" label="Name"/>
                    <ReferenceField label="resources.task.list.season" resource="season" source="seasonId" reference="season">
                        <TextField source="name.de" />
                    </ReferenceField>
                </Datagrid>
            }
        />
    </List>
);


const CategoryName = ({ record }) => {
    return <span>Edit {record ? `"${record.name.de}"` : ''}</span>;
};

const editStyle = {
    detail: {
        display: 'block',
        verticalAlign: 'top',
        marginRight: '2em',
        minWidth: '4em',
    },
};

export const CategoryEdit = withStyles(editStyle, { name: 'category-edit' })(({ classes, ...props }) => (
    <Edit {...props} actions={<ReviewEditActions />} title={<CategoryName />}>
        <SimpleForm>
            { /*<DisabledInput source="id" formClassName={classes.detail} label="ID" /> */}
            <TextInput source="name.de" />


            <ReferenceInput label="User" source="userId" reference="users">
                <SelectInput optionText="name" />
            </ReferenceInput>

            <DateField source="created" formClassName={classes.detail} label="resources.task.list.created" />
            <TextInput source="articleId" formClassName={classes.detail} label="resources.task.list.articleId" />
            <TextInput source="gtin" formClassName={classes.detail} label="resources.task.list.gtin" />
            <TextInput source="season.name" formClassName={classes.detail} label="resources.task.list.season" />
            <TextInput source="season.year" formClassName={classes.detail} label="resources.task.list.year" />
            <TextInput source="category" formClassName={classes.detail} label="resources.task.list.category" />
            <SelectInput
                formClassName={classes.detail}
                optionText="name"
                source="status"
                choices={[{id: 1, name: "test"},{id: 2, name: "test2"}]}
            />

            
            {/*<LongTextInput source="comment" /> */}
        </SimpleForm>
    </Edit>
));

export const CategoryCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput label="resources.task.list.season" source="seasonId" reference="season">
                <SelectInput optionText="name.de" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
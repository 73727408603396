import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import compose from 'recompose/compose';

import React from 'react';
import {
  translate,
  changeLocale,
  showNotification as showNotificationAction,
  GET_ONE,
  GET_LIST,
  UPDATE
} from 'react-admin';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/* Material UI */
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';

import AccountCircle from '@material-ui/icons/AccountCircle';
// import Select from 'react-select';
// import Chip from '@material-ui/core/Chip';
// import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';

/* Components */
import dataProvider from '../dataProvider';

const styles = theme => ({
  root: {
    color: 'rgba(0, 0, 0, 0.8)',
    width: '90% !important',
    padding: 20,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
  },
  selectRoot: {
    display: 'block',
    flexGrow: 1,
    marginTop: 10
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  noFlex: {
    display: 'block'
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },

  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    fontSize: 16,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
});

// const components = {
//   Option,
//   Control,
//   NoOptionsMessage,
//   Placeholder,
//   SingleValue,
//   MultiValue,
//   ValueContainer,
// };

// function Option(props) {
//   return (
//     <MenuItem
//       buttonRef={props.innerRef}
//       selected={props.isFocused}
//       component="div"
//       style={{
//         fontWeight: props.isSelected ? 500 : 400,
//       }}
//       {...props.innerProps}
//     >
//       {props.children}
//     </MenuItem>
//   );
// }

// function Control(props) {
//   return (
//     <TextField
//       fullWidth
//       InputProps={{
//         inputComponent,
//         inputProps: {
//           className: props.selectProps.classes.input,
//           ref: props.innerRef,
//           children: props.children,
//           ...props.innerProps,
//         },
//       }}
//     />
//   );
// }

// function NoOptionsMessage(props) {
//   return (
//     <Typography
//       color="textSecondary"
//       className={props.selectProps.classes.noOptionsMessage}
//       {...props.innerProps}
//     >
//       Keine weiteren Segmente ...
//     </Typography>
//   );
// }

// function Placeholder(props) {
//   return (
//     <Typography
//       color="textSecondary"
//       className={props.selectProps.classes.placeholder}
//       {...props.innerProps}
//     >
//       {props.children}
//     </Typography>
//   );
// }

// function SingleValue(props) {
//   return (
//     <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
//       {props.children}
//     </Typography>
//   );
// }

// function ValueContainer(props) {
//   return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
// }

// function MultiValue(props) {
//   return (
//     <Chip
//       tabIndex={-1}
//       label={props.children}
//       className={classNames(props.selectProps.classes.chip, {
//         [props.selectProps.classes.chipFocused]: props.isFocused,
//       })}
//       onDelete={event => {
//         props.removeProps.onClick();
//         props.removeProps.onMouseDown(event);
//       }}
//     />
//   );
// }

// function inputComponent({ inputRef, ...props }) {
//   return <div ref={inputRef} {...props} />;
// }


class ControlledExpansionPanels extends React.Component {
  constructor(props) {
    super(props);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.state = {
      getNotifications: true,
      vacationMode: false,
      isLoading: false,
      success: false,
      multi: [],
      vacation: {
        date: 'JJJJ-MM-TT',
        deputy: ''
      },
      segments: [],
      userSegments: [],
      mailValid: true,
      chosen: [],
      segments_de: [],
      segments_at: [],
    };
  }
  
  componentDidMount() {
    dataProvider(GET_LIST,'category')
    .then(items => {

      // console.log(items);

      var groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      

      const segments = items.data.map(c => ({
        label: `${c.name.de}`,
        value: `${c.name.de}`,
        season: c.seasonId.name.de,
        country: c.seasonId.countryId,
        id: c.id
      }))
       
      this.setState({
        segments: groupBy(segments, 'label'),
        segments_de: segments.filter(s => s.country.shortName === "DE"),
        segments_at: segments.filter(s => s.country.shortName === "AT")
      })

      console.log(this.state);
      // Object.entries(this.state.segments).map(segment => {
      //   console.log(segment)
      // })
    });

    dataProvider(GET_ONE,'user')
    .then(user => {
      user=user.data;
      if(user.segments){
        this.setState({
          chosen: user.segments.map(segment => ({
            name: segment.name,
            id: segment.id
          }))
        });
        user.segments.forEach(segment => {
          this.setState({
            [segment.name]: true
          })
        })
      }
      if(user.settings)
        this.setState({
          getNotifications: user.settings.getNotifications,
          vacationMode: user.settings.vacation.active || false,
          vacation: {
            deputy: user.settings.vacation.deputy || null,
            date: user.settings.vacation.date || null
          }
        })
    });
  }

  handleChange = segment => event => {

    if(event.target.checked){
      segment[1].forEach(seg => {
        this.setState(prevState => ({
          chosen: [...prevState.chosen, { id: seg.id, name: segment[0] }]
        }))
      })
    }
    else {
      // const arr = this.state.chosen.filter(c => c.name !== segment[0]);
      // console.log(arr);
      this.setState({ chosen: this.state.chosen.filter(c => c.name !== segment[0]) })

    }

    this.setState({ [segment[0]]: event.target.checked });
    // this.setState({ chosen: [...this.state.chosen, { id: segment.id, name: segment.value }] });

    console.log(this.state.chosen)
  };

  handleVacationChange = name => event => {
    if(name === 'deputy')
      this.setState({
        mailValid: event.target.value.match(/mdlz.com/g)
      })

    this.setState({
      vacation: {
        ...this.state.vacation,
        [name]: event.target.value
      }
    })
  }

  handleSegmentChange = name => value => {
    this.setState({
      [name]: value,
    });
  }

  handleSaveButtonClick() {
    const { isLoading } = this.state;


    if (!isLoading) {
      this.setState(
        {
          isLoading: true,
          success: false,
        },
        () => {
          dataProvider(UPDATE, 'user', {
            data: {
              // segments: this.state.chosen.map(segment => ({ id: segment.id, name: segment.label })),
              segments: this.state.chosen,
              getNotifications: this.state.getNotifications,
              vacation: { ...this.state.vacation, active: this.state.vacationMode }
            }
          })
          .then(data => {
            this.setState({
              isLoading: false,
              success: true,
            }); 
          });
        });
    }

  }

  // handleChange = panel => (event, expanded) => {
  //   this.setState({
  //     expanded: expanded ? panel : false,
  //   });
  // };

  handleChangeInput = name => event => {
    if(name === 'vacationMode')
      this.setState({ getNotifications: this.state[name] });
    // else if(name === 'getNotifications')
      // this.setState({ vacationMode: this.state[name] });
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes, translate } = this.props;
    const { success, isLoading, segments,
      // segments_at, segments_de
    } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    return (
      <div>

        <Paper basepath="/configuration" className={classes.root}>
          <Typography variant="headline" component="h3">Segmente</Typography>
          <Typography component="p">
            Sie erhalten nur Benachrichtigungen für ausgewählte Segmente
          </Typography>
          <div className={classes.selectRoot}>

          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <Typography component="p">Deutschland</Typography>
              <FormControl component="fieldset" >
                { Object.entries(segments).map(segment => (
                <FormControlLabel
                  key={segment[1][0].id}
                  control={
                    <Checkbox
                      checked={this.state[segment[0]]}
                      onChange={this.handleChange(segment)}
                      value={segment[0]}
                      color="primary"
                    />
                  }
                  label={segment[0]}
                />
              ))}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography component="p">Österreich</Typography>
            </Grid>
          </Grid>






{/*
            <Select
              classes={classes}
              options={this.state.segments}
              components={components}
              value={this.state.multi}
              onChange={this.handleSegmentChange('multi')}
              placeholder="W&auml;hlen Sie Ihre Segmente"
              isMulti
            />
*/}

          </div>
        </Paper>

        <Paper className={classes.root}>
          <Typography variant="headline" component="h3">Mitteilungen</Typography>

          <FormControlLabel
            control={
              <Switch
                checked={this.state.getNotifications}
                onChange={this.handleChangeInput('getNotifications')}
                color="primary"
              />
            }
            label="Mitteilungen erhalten"
          />

          <FormControlLabel
            control={
              <Switch
                checked={this.state.vacationMode}
                onChange={this.handleChangeInput('vacationMode')}
                color="primary"
              />
            }
            label="Urlaubsmodus"
          />

          { this.state.vacationMode ? 
            <div className={classes.margin}>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <AccountCircle />
                </Grid>
                <Grid item>
                  <TextField
                    id="input-with-icon-grid"
                    error={!this.state.mailValid}
                    onChange={this.handleVacationChange('deputy')}
                    label="Vertretung"
                    placeholder="vertretung@mdlz.com"
                    defaultValue={this.state.vacation.deputy}
                  />
                </Grid>
                <Grid item>
                  <div>
                    <TextField
                      id="date"
                      label="Rückkehrdatum"
                      type="date"
                      defaultValue={this.state.vacation.date}
                      className={classes.input}
                      onChange={this.handleVacationChange('date')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
           : null }


      </Paper>
      <Paper className={classes.root}>

        <Button
            variant="contained"
            color="primary"
            className={buttonClassname}
            onClick={this.handleSaveButtonClick}
        >
          <SaveIcon className={classes.leftIcon}/>
          {translate('configuration.saveButton')}
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </Paper>
      </div>
    );
  }
}

ControlledExpansionPanels.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    locale: state.i18n.locale
});

export default compose(
    connect(mapStateToProps, {
        changeLocale,
        showNotification: showNotificationAction,
    }),
    translate,
    withStyles(styles)
)(ControlledExpansionPanels);

// export default withStyles(styles)(ControlledExpansionPanels);
const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve({src:reader.result, title: file.title});
    
    reader.onerror = reject;
});



/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => (type, resource, params) => {
    if (type === 'UPDATE' && resource === 'task') {
        if (params.data.files && params.data.files.length) {
            // only freshly dropped pictures are instance of File
            const formerPictures = params.data.files.filter(p => !(p.rawFile instanceof File));
            const newPictures = params.data.files.filter(p => p.rawFile instanceof File);
            // console.log("newPictures",newPictures)
            return Promise.all(newPictures.map(convertFileToBase64))
                .then(base64Pictures => base64Pictures.map(picture64 => {
                    return({
                        src: picture64.src,
                        title: `${picture64.title}`,
                    })}))
                .then((transformedNewPictures) => {
                    return requestHandler(type, resource, {
                        ...params,
                        data: {
                            ...params.data,
                            // xfiles: formData,
                            files: [...transformedNewPictures, ...formerPictures],
                        },
                })});
        }
    }
    return requestHandler(type, resource, params);
};



export default addUploadFeature;
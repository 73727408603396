import React from 'react';
import { List, Datagrid, TextField, Responsive, Create, SimpleForm, TextInput } from 'react-admin';
// import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Comment';

import MobileGrid from './MobileGrid';

export const TaskIcon = Icon;


export const SeasonList = (props) => (
    <List {...props} title="All Seasons" >
        <Responsive
            xsmall={<MobileGrid />}
            medium={
                <Datagrid>
                    <TextField source="name.de" label="Name"/>
                </Datagrid>
            }
        />
    </List>
);

export const SeasonCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name.de" label="Name" placeholder="z.B. Ostern 2019" />
            <TextInput source="year" label="resources.task.list.year" />
        </SimpleForm>
    </Create>
);
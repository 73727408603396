import React, { Component } from 'react';
import {JssProvider} from 'react-jss';
import { Admin, Resource, resolveBrowserLocale } from 'react-admin';
// import jsonServerProvider from 'ra-data-json-server';
import { createMuiTheme } from '@material-ui/core/styles';


import './App.css';
import { generateClassName } from './generateClassName';
import englishMessages from './i18n/en';
import germanMessages from './i18n/de';
// import TaskEdit from './tasks/edit'; // ToDo
// import TaskList from './tasks/list';
// import TaskShow from './tasks/show';
import { SeasonList, SeasonCreate } from './season/';
import { CategoryList, CategoryCreate } from './category/';
import { FilesList } from './files/';
import Dashboard from './dashboard';
import Menu from './MetisMenu';
import customRoutes from './routes';
// import Login from './loginPage';

// import logoutButton from './logoutButton';
import NotFound from './NotFound';
import asyncComponent from "./components/AsyncComponent";
import dataProvider from './dataProvider';
import addUploadFeature from './addUploadFeature';
import authProvider from './authProvider';

const AsyncLogin = asyncComponent(() => import('./loginPage'));
const AsyncTaskEdit = asyncComponent(() => import('./tasks/edit'));
const AsyncTaskList = asyncComponent(() => import('./tasks/list'));
const AsyncTaskShow = asyncComponent(() => import('./tasks/show'));
const AsyncTaskCreate = asyncComponent(() => import('./tasks/create'));

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: '#757ce8',
      main: '#7d68ac',
      // dark: '#002884',
      // contrastText: '#fff',
    },
    secondary: {
      // light: '#ff7961',
      main: '#7d68ac',
      // dark: '#ba000d',
      // contrastText: '#fff',
    },
  },
});

const messages = {
    de: germanMessages,
    en: englishMessages,
}
const i18nProvider = locale => messages[locale];

// const dataProvider = jsonServerProvider('http://192.168.0.154/api');

const uploadCapableDataProvider = addUploadFeature(dataProvider);

class App extends Component {
    async componentWillMount() {
    }

    componentWillUnmount() {
    }

    render() {
      return (
        <JssProvider generateClassName={generateClassName}>
          <Admin
            // locale="de"
            dashboard={Dashboard}
            theme={theme}
            title="MILKA releaser"
            authProvider={authProvider}
            dataProvider={uploadCapableDataProvider}
            locale={resolveBrowserLocale()}
            i18nProvider={i18nProvider}
            menu={Menu}
            customRoutes={customRoutes}
            loginPage={AsyncLogin}
            catchAll={NotFound}
            // logoutButton={logoutButton}
          >
            <Resource name="task"
              list={AsyncTaskList}
              edit={AsyncTaskEdit}
              show={AsyncTaskShow}
              create={AsyncTaskCreate}
            />
            <Resource name="season" list={SeasonList} create={SeasonCreate} />
            <Resource name="category" list={CategoryList} create={CategoryCreate} />
            <Resource name="files" list={FilesList} />
            <Resource name="user" />
            <Resource name="menu" />
            <Resource name="nav" />
            <Resource name="country" />
            <Resource name="configuration" />
          </Admin>
        </JssProvider>
      );
    }
}


export default App;

import germanMessages from 'ra-language-german';

export default {
    ...germanMessages,
    pos: {
        search: 'Suche',
        configuration: 'Einstellungen',
        language: 'Sprache',
        theme: {
            name: 'Theme',
            light: 'Hell',
            dark: 'Dunkel',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            new_customers: 'New Customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to react-admin demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Fell free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                aor_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
    },
    resources: {
        task: {
            name: "Auftrag |||| Aufträge",
            title: '',
            status: {
                accepted: 'Akzeptiert',
                rejected: 'Abgelehnt',
                pending: 'Ausstehend',
            },
            list: {
                owner: "Ersteller",
                articleId: "Artikel-Nr.",
                gtin: "GTIN",
                gtin_rk: "SRP / CASE GTIN",
                gtin_st: "GTIN Einzelartikel (ST)",
                season: "Saison",
                category: "Segment",
                seasons: {
                    Ostern: "Ostern",
                    Weihnachten: "Weihnachten"
                },
                created: "Erstellt",
                year: "Jahr",
                status: {
                    accepted: "Akzeptiert",
                    pending: "Ausstehend",
                    rejected: "Abgelehnt"
                }
            },
            edit: {
                tabs: {
                    info: 'Informationen',
                    files: 'Ansichtsdateien',
                    finalFiles: 'Druckdaten',
                    notification: 'Benachrichtigung',
                    history: 'Verlauf'
                },
                user: 'Benutzer'
            },
            imageTypes: {
                label: "Image Types",
                items: {
                    "C": "Product Image (High Resolution) (Primary)",
                    "H": "Mobile Ready Hero Image (MRHI) (Optimised)",
                    "U": "Optimised Hero Image (Optimised) ",
                    "3DR": "3D Rendered (Optimised)",
                    "R": "Ambience/Mood (Secondary)",
                    "N": "Application (Secondary)",
                    "L3": "Barcode(Secondary)",
                    "L7": "Certification Seals/Claims  (Secondary)",
                    "T": "Content/Texture (Secondary)",
                    "F": "Detail/Technology Image (Secondary)",
                    "L4": "Ingredients (Secondary)",
                    "L6": "Marketing Content/QR Code (Secondary)",
                    "M": "Montage/Composition Image (Secondary)",
                    "L2": "Nutritional Label (Secondary)",
                    "L5": "Nutritional/Ingredients combined (Secondary)",
                    "L9": "Petfood Feeding Instructions/Ingredients (Secondary)",
                    "N/A": "Planogram (Technical)",
                    "L8": "Preparation Instructions (Secondary)",
                    "A": "Product Image (web)  (Primary)",
                    "E": "Product Image 360°/3D (Optimised)",
                    "D": "Product Image with Supporting Elements (High Resolution) (Primary)",
                    "B": "Product Image with Supporting Elements (web) (Primary)",
                    "L10": "Safe Handling Instructions (Secondary)",
                    "L1": "Sample (Prototype)/Mock-up Image/Full Flat (Technical)",
                    "S": "Sidekick (Secondary)",
                    "Q": "Size comparison (Secondary)",
                    "K": "Social Media (Secondary)",
                }
            },
            fields: {
                username: 'Benutzer'
            },
            show: {
                tabs: {
                    accepted: 'Akzeptiert',
                    baseInformation: 'Basisinformationen',
                    files: 'Dateien',
                    notification: 'Benachrichtigungen',
                    history: 'Verlauf',
                    review: 'Freigabe',
                    attachments: 'Anhänge',
                }
                
            }
        },
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                commands: 'Orders',
                groups: 'Segments',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                total_spent: 'Total spent',
            },
            tabs: {
                identity: 'Identity',
                address: 'Address',
                orders: 'Orders',
                reviews: 'Reviews',
                stats: 'Stats',
            },
            page: {
                delete: 'Delete Customer',
            },
        },
        commands: {
            name: 'Order |||| Orders',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    total: 'Total',
                    unit_price: 'Unit Price',
                },
                customer_id: 'Customer',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
            },
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
        },
        category: {
            name: 'Segment |||| Segmente',
            fields: {
                products: 'Products',
            },
        },
        reviews: {
            name: 'Auftrag |||| Aufträge',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Datum',
                comment: 'Kommentar',
                rating: 'Rating',
            },
            action: {
                accept: 'Akzeptieren',
                reject: 'Ablehnen',
                reset: 'Zurücksetzen',
            },
            notification: {
                approved_success: 'Auftrag wurde akzeptiert',
                approved_error: 'Fehler: Auftrag wurde nicht akzeptiert',
                rejected_success: 'Auftrag abgelehnt',
                rejected_error: 'Fehler: Auftrag wurde nicht abgelehnt',
            },
            reject: {
                reason: 'Bitte geben Sie einen Grund an',
                reasonTitle: 'Grund',
                cancel: 'Abbrechen'
            }
        },
        segments: {
            name: 'Segments',
            fields: {
                customers: 'Customers',
                name: 'Name',
            },
            data: {
                compulsive: 'Compulsive',
                collector: 'Collector',
                ordered_once: 'Ordered once',
                regular: 'Regular',
                returns: 'Returns',
                reviewer: 'Reviewer',
            },
        },
        files: {
            name: 'Dateien'
        },
        user: {
            name: 'Benutzer'
        }
    },
    login: {
        failed: "Passwort und E-Mail-Adresse stimmen nicht überein",
        notActivated: "E-Mail-Adresse nicht bestätigt",
        mail: "E-Mail-Adresse",
        welcome: 'Login',
    },
    register: {
        title: 'Registrierung',
        mail: "E-Mail-Adresse",
        firstName: 'Vorname',
        lastName: 'Nachname',
        password: 'Passwort',
        passwordRepeat: 'Passwort wiederholen',
        passwordTooWeak: 'Passwort zu schwach (mindestens 8 Zeichen)',
        button: 'Registrieren',
        passwordsDontMatch: 'Passwörter stimmen nicht überein',
        unauthorized: 'Nicht authorisierte E-Mail-Adresse',
        success: 'Registrierung erfolgreich',
        mailExists: 'E-Mail-Adresse existiert bereits im System',
        verify: {
            button: 'E-Mail-Adresse bestätigen'
        }
    },
    configuration: {
        title: 'Einstellungen',
        generalSettings: 'Allgemeine Einstellungen',
        notification: {
            title: 'Benachrichtigungen',
            getAllNotifications: 'Sämtliche Benachrichtigungen erhalten',
            getSomeNotifications: 'Nur bestimmte Benachrichtigungen erhalten',
        },
        saveButton: 'Speichern',
        save: {
            good: 'Einstellungen erfolgreich gespeichert',
            bad: 'Einstellungen konnten nicht gespeichert werden'
        }
    }
};

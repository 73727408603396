// import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_CHECK } from 'react-admin';
// import decodeJwt from 'jwt-decode';
// import dataProvider from './dataProvider/';

// export default (type, params) => {
//     // console.log(type, params);
//     if (type === AUTH_LOGIN) {
//         const { username, password } = params;

//         return dataProvider("LOGIN", 'user', { data: { username: username, password: password } })
//         .then((response) => {
//             if (response.status < 200 || response.status >= 300) {
//               throw new Error("login.failed");
//             }
//             return response.data;
//         })
//         .then(({ token }) => {
//             const decodedToken = decodeJwt(token);
//             localStorage.setItem('token', token);
//             localStorage.setItem('role', decodedToken.user.role);
//         })
//         .catch((e) => {
//             console.error('[LOGIN ERROR]',e);
//             return e;
            
//         });
//     }
//     if (type === AUTH_LOGOUT) {
//         localStorage.removeItem('token');
//         localStorage.removeItem('role');
//         return Promise.resolve();
//     }
//     if (type === AUTH_ERROR) {
//         console.log("auth error");
//     }
//     if (type === AUTH_CHECK) {
//         return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
//     }
//     if (type === AUTH_GET_PERMISSIONS) {
//         const role = localStorage.getItem('role');
//         return role ? Promise.resolve(role) : Promise.reject();
//     }
//     return Promise.reject('Unkown method');
// };

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_CHECK } from 'react-admin';
import decodeJwt from 'jwt-decode';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        // const request = new Request('http://localhost/api/user/login', {
        const request = new Request(`${process.env.REACT_APP_API}/user/login`, {
            method: 'POST',
            body: JSON.stringify({ username: username, password: password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status === 401)
                    throw new Error("login.failed");
                if (response.status === 403)
                    throw new Error("login.notActivated");

                // if (response.status < 200 || response.status >= 300) {
                //   throw new Error("login.failed");
                // }
                return response.json();
            })
            .then(({ token }) => {
                const decodedToken = decodeJwt(token);
                localStorage.setItem('token', token);
                localStorage.setItem('role', decodedToken.user.role);
                localStorage.setItem('lastLogin', decodedToken.user.lastLogin);
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        console.log("auth error");
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    }
    return Promise.reject('Unkown method');
};
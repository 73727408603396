import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
// import { TaskList as Task } from './tasks/list';
// import PasswordLost from './passwordLost/';
// import SetPassword from './passwordLost/setPassword';
import Download from './download/';
// import Verify from './register/verify';
import asyncComponent from "./components/AsyncComponent";
// import Segments from './segments/Segments';

const AsyncRegister = asyncComponent(() => import('./register/'));
const AsyncPasswordLost = asyncComponent(() => import('./passwordLost/'));
const AsyncSetPassword = asyncComponent(() => import('./passwordLost/setPassword'));
const AsyncVerify = asyncComponent(() => import('./register/verify'));

export default [

    <Route exact path="/configuration" component={Configuration} />,

    <Route exact path="/register" component={AsyncRegister} noLayout/>,
    <Route exact path="/passwordlost" component={AsyncPasswordLost} noLayout/>,
    <Route exact path="/passwordlost/:hash" component={AsyncSetPassword} noLayout/>,
    <Route exact path="/download/:id" component={Download} noLayout/>,
    <Route path="/verify/:hash" component={AsyncVerify} noLayout/>,

];

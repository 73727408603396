import englishMessages from 'ra-language-english';

export default {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            new_customers: 'New Customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to react-admin demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Fell free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                aor_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
    },
    resources: {
        task: {
            name: "Task |||| Tasks",
            title: 'All tasks',
            list: {
                owner: "Owner",
                articleId: "Article ID",
                gtin: "GTIN",
                gtin_rk: "SRP / CASE GTIN",
                gtin_st: "GTIN single article (ST)",
                season: "Season",
                category: "Category",
                seasons: {
                    Ostern: "Easter",
                    Weihnachten: "Christmas"
                },
                created: "Created",
                year: "Year",
                status: {
                    accepted: "Accepted",
                    pending: "Pending",
                    rejected: "Rejected"
                }
            },
            edit: {
                tabs: {
                    info: 'Informations',
                    files: 'Files',
                    notification: 'Notifications',
                    history: 'Process'
                },
                user: 'User'
            },
            fields: {
                username: 'User'
            },
            show: {
                accepted: 'Accepted',
                tabs: {
                    baseInformation: 'Base information',
                    files: 'Files',
                    notification: 'Notifications',
                    history: 'history',
                    review: 'Review'
                }
            }
        },
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                commands: 'Orders',
                groups: 'Segments',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                total_spent: 'Total spent',
            },
            tabs: {
                identity: 'Identity',
                address: 'Address',
                orders: 'Orders',
                reviews: 'Reviews',
                stats: 'Stats',
            },
            page: {
                delete: 'Delete Customer',
            },
        },
        commands: {
            name: 'Order |||| Orders',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    total: 'Total',
                    unit_price: 'Unit Price',
                },
                customer_id: 'Customer',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
            },
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
        },
        category: {
            name: 'Category |||| Categories',
            fields: {
                products: 'Products',
            },
        },
        reviews: {
            name: 'Review |||| Reviews',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
            reject: {
                reason: 'Bitte geben Sie einen Grund an',
                reasonTitle: 'Reason',
                cancel: 'Cancel'
            }
        },
        segments: {
            name: 'Segments',
            fields: {
                customers: 'Customers',
                name: 'Name',
            },
            data: {
                compulsive: 'Compulsive',
                collector: 'Collector',
                ordered_once: 'Ordered once',
                regular: 'Regular',
                returns: 'Returns',
                reviewer: 'Reviewer',
            },
        },
        files: {
            name: 'Files'
        },
        user: {
            name: 'Users'
        }
    },
    login: {
        failed: "Password or username is incorrect",
        mail: "Email address",
        welcome: 'Login',
    },
    register: {
        title: 'Register',
        mail: "Email address",
        firstName: 'First name',
        lastName: 'Last name',
        password: 'Password',
        passwordRepeat: 'Repeat password',
        passwordTooWeak: 'Password is too weak',
        button: 'Register',
        passwordsDontMatch: 'Passwords do not match',
        unauthorized: 'Email address is not authorized'
    }
};

import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
        marginLeft: 15,
        marginBottom: 15,
    }
}

class Welcome extends React.Component {
    render() {
        const { classes, user } = this.props;
        return (
            <Card className={classes.root}>
                <CardContent>
                    <Typography variant="headline" component="h2">
                        {`Hallo ${user.firstname} ${user.lastname}`}         
                    </Typography>
                    <Typography component="p">
                        Herzlich willkommen beim Freigabetool von MILKA und R&amp;R
                    </Typography>
                    { (!user.segments || user.segments.length < 1) && <Typography component="p"><a style={{color: 'black'}} href="/#/configuration">W&auml;hlen Sie jetzt Ihre Segmente aus!</a></Typography>}
                </CardContent>
            </Card>
        )
    }
}
export default withStyles(styles, { name: 'dashboard-welcome' })(Welcome);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Card from '@material-ui/core/Card';
import dataProvider from '../dataProvider';
// import FirstLoginModal from './firstLoginModal';
import Welcome from './welcome';
import Chart from './chart';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import grey from '@material-ui/core/colors/grey';

import { GET_ONE } from 'react-admin';

const styles = {
  root: {
    marginBottom: 25,
    padding: 15,
    margin: '0px 15px 30px 15px',
  },
  card: {
    maxWidth: 345,
    minWidth: 250,
    display: 'inline-block',
    margin: '0px 15px 30px 15px',
    padding: 15,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
};

// function SimpleMediaCard(props) {
class SimpleMediaCard extends Component {

  state = { 
    lastLogin: localStorage.getItem('lastLogin'),
    data: [],
    user: {},
  };

  componentDidMount(){
    dataProvider("GET_STATS", 'stats', { id: 'stats' })
    .then(data => {
      data=data.data;
      const obj = data.map((item) => (
         {
          name: item.name,
          id: item.id,
          countryId: item.countryId,
          labels: [
            'Abgeschlossen',
            'Abgelehnt',
            'Ausstehend',
            'Unbearbeitet'
          ],
          datasets: [{
            data: [item.accepted, item.rejected, item.pending, item.untouched],
            backgroundColor: [
            green[500],
            red[500],
            yellow[200],
            grey[200]
            ],
            hoverBackgroundColor: [
            green[400],
            red[400],
            yellow[100],
            grey[100]
            ]
          }]
        }
      ))
      this.setState({ data: obj });
    });

    dataProvider(GET_ONE, 'user')
    .then(user => {
      this.setState({
        user: user.data
      })
    })
    // get infos for charts via dataProvider()
  }

  render(){
    
    const {
      // lastLogin,
      data,
      user
    } = this.state;
    // const { classes } = this.props;
    return (
      <div>
        <Welcome user={user} />
      { data.map((item,i) => <Chart key={i} item={item} /> )}

        {/*
        { lastLogin === 'null' ?
        <Card className={classes.card} style={{display: 'none'}}>
          <FirstLoginModal /> 
        </Card>
        : null}
        */}

      </div>
    );
  }
  
}

SimpleMediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { name: 'dashboard-index' } )(SimpleMediaCard);